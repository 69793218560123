import window from 'global';

export const segmentIdentify = (...args) => {
  // Identify: Who is the user?
  // https://segment.com/docs/connections/spec/identify/#traits
  window.analytics?.identify(...args);
};

export const segmentTrack = (...args) => {
  // Track: What are they doing?
  // https://segment.com/docs/connections/spec/track/
  window.analytics?.track(...args);
};

export const segmentPage = (...args) => {
  // Screen: What app screen are they on?
  // https://segment.com/docs/connections/spec/screen/
  window.analytics?.page(...args);
};

export const segmentScreen = (...args) => {
  // Screen: What app screen are they on?
  // https://segment.com/docs/connections/spec/screen/
  window.analytics?.screen(...args);
};

export const segmentGroup = (orgId, data) => {
  // Group: What account or organization are they part of?
  // https://segment.com/docs/connections/spec/group/
  if (Array.isArray(orgId)) {
    orgId.forEach(orgIds => {
      window.analytics?.group(orgIds.id, { ...orgIds.data });
    });
  } else {
    window.analytics?.group(orgId, { ...data });
  }
};

export const segmentAlias = (...args) => {
  // Alias: What was their past identity?
  // https://segment.com/docs/connections/spec/alias/
  window.analytics?.alias(...args);
};
