import keyMirror from 'keymirror';
import { create } from 'react-test-renderer';
import { createAsyncActionSet } from '../lib/action-helper';

const syncActionSet = keyMirror({
  START_ENTER_DETAILS_TIMER: undefined,
  STOP_ENTER_DETAILS_TIMER: undefined,
  SELECT_SLOT: undefined,
});

export default {
  ...syncActionSet,
  GET_SALVAGE_OFFER_FORM_CONFIG: createAsyncActionSet('GET_SALVAGE_OFFER_FORM_CONFIG'),
  SET_SALVAGE_OFFER_START_SEGMENT: createAsyncActionSet('SET_SALVAGE_OFFER_START_SEGMENT'),
  GET_SALVAGE_OFFER_PRICE: createAsyncActionSet('GET_SALVAGE_OFFER_PRICE'),
  GET_QUOTE_PRICE: createAsyncActionSet('GET_QUOTE_PRICE'),
  GET_QUOTE_BY_TOKEN: createAsyncActionSet('GET_QUOTE_BY_TOKEN'),
  GET_ADDRESSES_BY_POSTCODE: createAsyncActionSet('GET_ADDRESSES_BY_POSTCODE'),
  CREATE_QUOTE: createAsyncActionSet('CREATE_QUOTE'),
  CREATE_QUOTE_WITHOUT_INSTANT_PRICE:createAsyncActionSet('CREATE_QUOTE_WITHOUT_INSTANT_PRICE'),
  ENTER_DETAILS: createAsyncActionSet('ENTER_DETAILS'),
  SET_ONLINE_QUOTE: createAsyncActionSet('SET_ONLINE_QUOTE'),
  ARRANGE_COLLECTION: createAsyncActionSet('ARRANGE_COLLECTION'),
  COMPLETE_ARRANGE_COLLECTION: createAsyncActionSet('COMPLETE_ARRANGE_COLLECTION'),
  SET_PAYMENT_METHOD: createAsyncActionSet('SET_PAYMENT_METHOD'),
  SET_PAYMENT_METHOD_INPUT_CHANGE_SEGMENT: createAsyncActionSet('SET_PAYMENT_METHOD_INPUT_CHANGE_SEGMENT'),
  CANCEL_QUOTE: createAsyncActionSet('CANCEL_QUOTE'),
  REDIRECT_TO_STEP_BY_QUOTE_STATUS: 'REDIRECT_TO_STEP_BY_QUOTE_STATUS',
  RE_QUOTE: 'RE_QUOTE',
  DROP_QUOTE: createAsyncActionSet('DROP_QUOTE'),
  CONTINUE_WITH_QUOTE: createAsyncActionSet('CONTINUE_WITH_QUOTE'),
  CONTINUE_WITH_QUOTE_SHORT_LINK: createAsyncActionSet('CONTINUE_WITH_QUOTE_SHORT_LINK'),
  REFRESH_PRICE: createAsyncActionSet('REFRESH_PRICE'),
  HANDLE_QUOTE_FORBIDDEN_STATUS: createAsyncActionSet('HANDLE_QUOTE_FORBIDDEN_STATUS'),
  HANDLE_QUOTE_UNAUTHORIZED_STATUS: createAsyncActionSet('HANDLE_QUOTE_UNAUTHORIZED_STATUS'),
  HANDLE_QUOTE_BAD_STATUS: createAsyncActionSet('HANDLE_QUOTE_BAD_STATUS'),
  SET_MANUAL_ADDRESS: createAsyncActionSet('SET_MANUAL_ADDRESS'),
  SAVE_ABANDONED_DATA: createAsyncActionSet('SAVE_ABANDONED_DATA'),
  GET_BOOKING_SLOTS: createAsyncActionSet('GET_BOOKING_SLOTS'),
  FETCH_SCRAP_APPLICATION: createAsyncActionSet('FETCH_SCRAP_APPLICATION'),
  RESET_ADDRESSES: 'RESET_ADDRESSES',
  CANCEL_CONDITION_OFFER: createAsyncActionSet('CANCEL_CONDITION_OFFER'),
  CANCEL_CONDITION_OFFER_BY_TOKEN: createAsyncActionSet('CANCEL_CONDITION_OFFER_BY_TOKEN'),
  SUBMIT_VEHICLE_DETAIL: createAsyncActionSet('SUBMIT_VEHICLE_DETAIL'),
  UPDATE_VEHICLE_DETAIL: createAsyncActionSet('UPDATE_VEHICLE_DETAIL'),
  CUSTOMER_REQUEST_CALL_BACK: createAsyncActionSet('CUSTOMER_REQUEST_CALL_BACK'),
  SEND_CAPTURE_FORM_SMS: createAsyncActionSet('SEND_CAPTURE_FORM_SMS'),
  CONTINUE_WITH_CAPTURE_SHORT_LINK: createAsyncActionSet('CONTINUE_WITH_CAPTURE_SHORT_LINK'),
  FETCH_CAPTURE_FORM_CONDITIONS: createAsyncActionSet('FETCH_CAPTURE_FORM_CONDITIONS'),
  SET_SEND_SMS_BUTTON_STATUS: createAsyncActionSet('SET_SEND_SMS_BUTTON_STATUS'),
  UPLOAD_CAPTURE_FORM_MEDIA: createAsyncActionSet('UPLOAD_CAPTURE_FORM_MEDIA'),
  UPLOAD_CAPTURE_FORM_VIDEO: createAsyncActionSet('UPLOAD_CAPTURE_FORM_VIDEO'),
  UPLOAD_SALVAGE_OFFER_MEDIA: createAsyncActionSet('UPLOAD_SALVAGE_OFFER_MEDIA'),
  SET_BETTER_THAN_SCRAP_SEGMENT: createAsyncActionSet('SET_BETTER_THAN_SCRAP_SEGMENT'),
};
