import { createActions } from 'redux-actions';
import { segmentTrack } from 'lib/segment';

import QuoteTypes from '../action-types/quote';

export const {
  getQuotePriceRequest,
  getQuoteByTokenRequest,
  getAddressesByPostcodeRequest,
  getAddressesByPostcodeSuccess,
  getAddressesByPostcodeError,
} = createActions({
  [QuoteTypes.GET_QUOTE_PRICE.REQUEST]: payload => payload,
  [QuoteTypes.GET_QUOTE_BY_TOKEN.REQUEST]: payload => payload,
  [QuoteTypes.GET_ADDRESSES_BY_POSTCODE.REQUEST]: payload => payload,
  [QuoteTypes.GET_ADDRESSES_BY_POSTCODE.SUCCESS]: payload => payload,
  [QuoteTypes.GET_ADDRESSES_BY_POSTCODE.ERROR]: payload => payload,
});

export const {
  startEnterDetailsTimer,
  stopEnterDetailsTimer,
  createQuoteRequest,
  createQuoteSuccess,
  createQuoteError,
  createQuoteWithoutInstantPrice,
  handleQuoteForbiddenStatus,
  handleQuoteUnauthorizedStatus,
  handleQuoteBadStatus,
  enterDetailsRequest,
  enterDetailsSuccess,
  enterDetailsError,
  setOnlineQuoteRequest,
  setOnlineQuoteSuccess,
  setOnlineQuoteError,
  arrangeCollectionRequest,
  arrangeCollectionSuccess,
  arrangeCollectionError,
  setPaymentMethodRequest,
  setPaymentMethodSuccess,
  setPaymentMethodError,
  setPaymentMethodInputChangeSegmentRequest,
  cancelQuoteRequest,
  cancelQuoteSuccess,
  cancelQuoteError,
  cancelQuoteSuccessFromAccount,
  reQuote,
  completeArrangeCollection,
  dropQuote,
  continueWithQuoteRequest,
  continueWithQuoteSuccess,
  continueWithQuoteError,
  refreshPriceRequest,
  refreshPriceSuccess,
  refreshPriceError,
  saveAbandonedDataRequest,
  saveAbandonedDataSuccess,
  saveAbandonedDataError,
  continueWithQuoteShortLinkRequest,
  continueWithQuoteShortLinkSuccess,
  continueWithQuoteShortLinkError,
  setManualAddress,
  getBookingSlotsRequest,
  getBookingSlotsSuccess,
  getBookingSlotsError,
  selectSlot,
  fetchScrapApplicationRequest,
  fetchScrapApplicationSuccess,
  fetchScrapApplicationError,
  resetAddresses,
  cancelConditionOfferRequest,
  cancelConditionOfferSuccess,
  cancelConditionOfferError,
  cancelConditionOfferByTokenRequest,
  cancelConditionOfferByTokenSuccess,
  cancelConditionOfferByTokenError,
  submitVehicleDetailRequest,
  submitVehicleDetailSuccess,
  submitVehicleDetailError,

  updateVehicleDetailRequest,
  updateVehicleDetailSuccess,
  updateVehicleDetailError,

  customerRequestCallBackRequest,
  customerRequestCallBackSuccess,
  customerRequestCallBackError,

  sendCaptureFormSmsRequest,
  sendCaptureFormSmsSuccess,
  sendCaptureFormSmsError,

  continueWithCaptureShortLinkRequest,
  continueWithCaptureShortLinkSuccess,
  continueWithCaptureShortLinkError,

  fetchCaptureFormConditionsRequest,
  fetchCaptureFormConditionsSuccess,
  fetchCaptureFormConditionsError,

  setSendSmsButtonStatusRequest,

  uploadCaptureFormMediaRequest,
  uploadCaptureFormMediaSuccess,
  uploadCaptureFormMediaError,

  uploadCaptureFormVideoRequest,
  uploadCaptureFormVideoSuccess,
  uploadCaptureFormVideoError,

  getSalvageOfferPriceRequest,
  getSalvageOfferPriceSuccess,
  getSalvageOfferPriceError,

  getSalvageOfferFormConfigRequest,
  getSalvageOfferFormConfigSuccess,
  getSalvageOfferFormConfigError,

  setSalvageOfferStartSegmentRequest,

  uploadSalvageOfferMediaRequest,
  uploadSalvageOfferMediaSuccess,
  uploadSalvageOfferMediaError,

  redirectToStepByQuoteStatus,

  setBetterThanScrapSegmentRequest,
} = createActions({
  [QuoteTypes.START_ENTER_DETAILS_TIMER]: (field, value) => ({ field, value }),
  [QuoteTypes.STOP_ENTER_DETAILS_TIMER]: () => { },
  [QuoteTypes.CREATE_QUOTE.REQUEST]: payload => payload,
  [QuoteTypes.CREATE_QUOTE.SUCCESS]: payload => payload,
  [QuoteTypes.CREATE_QUOTE.ERROR]: payload => payload,
  [QuoteTypes.CREATE_QUOTE_WITHOUT_INSTANT_PRICE.actionName]: payload => payload,
  [QuoteTypes.HANDLE_QUOTE_FORBIDDEN_STATUS.actionName]: () => { },
  [QuoteTypes.HANDLE_QUOTE_UNAUTHORIZED_STATUS.actionName]: () => { },
  [QuoteTypes.HANDLE_QUOTE_BAD_STATUS.actionName]: () => { },
  [QuoteTypes.ENTER_DETAILS.REQUEST]: payload => payload,
  [QuoteTypes.ENTER_DETAILS.SUCCESS]: payload => payload,
  [QuoteTypes.ENTER_DETAILS.ERROR]: payload => payload,
  [QuoteTypes.SET_ONLINE_QUOTE.REQUEST]: payload => payload,
  [QuoteTypes.SET_ONLINE_QUOTE.SUCCESS]: payload => payload,
  [QuoteTypes.SET_ONLINE_QUOTE.ERROR]: payload => payload,
  [QuoteTypes.ARRANGE_COLLECTION.REQUEST]: payload => payload,
  [QuoteTypes.ARRANGE_COLLECTION.SUCCESS]: payload => payload,
  [QuoteTypes.ARRANGE_COLLECTION.ERROR]: payload => payload,
  [QuoteTypes.SET_PAYMENT_METHOD.REQUEST]: payload => payload,
  [QuoteTypes.SET_PAYMENT_METHOD.SUCCESS]: payload => payload,
  [QuoteTypes.SET_PAYMENT_METHOD.ERROR]: payload => payload,
  [QuoteTypes.SET_PAYMENT_METHOD_INPUT_CHANGE_SEGMENT.REQUEST]: payload => payload,
  [QuoteTypes.CANCEL_QUOTE.REQUEST]: payload => payload,
  [QuoteTypes.CANCEL_QUOTE.SUCCESS]: payload => payload,
  [QuoteTypes.CANCEL_QUOTE.ERROR]: payload => payload,
  [QuoteTypes.RE_QUOTE]: payload => payload,
  [QuoteTypes.COMPLETE_ARRANGE_COLLECTION.actionName]: () => { },
  [QuoteTypes.DROP_QUOTE.actionName]: () => { },
  [QuoteTypes.CONTINUE_WITH_QUOTE.REQUEST]: payload => payload,
  [QuoteTypes.CONTINUE_WITH_QUOTE.SUCCESS]: payload => payload,
  [QuoteTypes.CONTINUE_WITH_QUOTE.ERROR]: payload => payload,
  [QuoteTypes.REFRESH_PRICE.REQUEST]: payload => payload,
  [QuoteTypes.REFRESH_PRICE.SUCCESS]: payload => payload,
  [QuoteTypes.REFRESH_PRICE.ERROR]: payload => payload,
  [QuoteTypes.SAVE_ABANDONED_DATA.REQUEST]: payload => payload,
  [QuoteTypes.SAVE_ABANDONED_DATA.SUCCESS]: payload => payload,
  [QuoteTypes.SAVE_ABANDONED_DATA.ERROR]: payload => payload,
  [QuoteTypes.CONTINUE_WITH_QUOTE_SHORT_LINK.REQUEST]: payload => payload,
  [QuoteTypes.CONTINUE_WITH_QUOTE_SHORT_LINK.SUCCESS]: payload => payload,
  [QuoteTypes.CONTINUE_WITH_QUOTE_SHORT_LINK.ERROR]: payload => payload,
  [QuoteTypes.SET_MANUAL_ADDRESS.actionName]: payload => payload,
  [QuoteTypes.GET_BOOKING_SLOTS.REQUEST]: payload => payload,
  [QuoteTypes.GET_BOOKING_SLOTS.SUCCESS]: payload => payload,
  [QuoteTypes.GET_BOOKING_SLOTS.ERROR]: payload => payload,
  [QuoteTypes.SELECT_SLOT]: payload => payload,

  [QuoteTypes.FETCH_SCRAP_APPLICATION.REQUEST]: payload => payload,
  [QuoteTypes.FETCH_SCRAP_APPLICATION.SUCCESS]: payload => payload,
  [QuoteTypes.FETCH_SCRAP_APPLICATION.ERROR]: payload => payload,

  [QuoteTypes.RESET_ADDRESSES]: payload => payload,

  [QuoteTypes.CANCEL_CONDITION_OFFER.REQUEST]: payload => payload,
  [QuoteTypes.CANCEL_CONDITION_OFFER.SUCCESS]: payload => payload,
  [QuoteTypes.CANCEL_CONDITION_OFFER.ERROR]: payload => payload,

  [QuoteTypes.CANCEL_CONDITION_OFFER_BY_TOKEN.REQUEST]: payload => payload,
  [QuoteTypes.CANCEL_CONDITION_OFFER_BY_TOKEN.SUCCESS]: payload => payload,
  [QuoteTypes.CANCEL_CONDITION_OFFER_BY_TOKEN.ERROR]: payload => payload,

  [QuoteTypes.SUBMIT_VEHICLE_DETAIL.REQUEST]: payload => payload,
  [QuoteTypes.SUBMIT_VEHICLE_DETAIL.SUCCESS]: payload => payload,
  [QuoteTypes.SUBMIT_VEHICLE_DETAIL.ERROR]: payload => payload,

  [QuoteTypes.UPDATE_VEHICLE_DETAIL.REQUEST]: payload => payload,
  [QuoteTypes.UPDATE_VEHICLE_DETAIL.SUCCESS]: payload => payload,
  [QuoteTypes.UPDATE_VEHICLE_DETAIL.ERROR]: payload => payload,

  [QuoteTypes.CUSTOMER_REQUEST_CALL_BACK.REQUEST]: payload => payload,
  [QuoteTypes.CUSTOMER_REQUEST_CALL_BACK.SUCCESS]: payload => payload,
  [QuoteTypes.CUSTOMER_REQUEST_CALL_BACK.ERROR]: payload => payload,

  [QuoteTypes.SEND_CAPTURE_FORM_SMS.REQUEST]: payload => payload,
  [QuoteTypes.SEND_CAPTURE_FORM_SMS.SUCCESS]: payload => payload,
  [QuoteTypes.SEND_CAPTURE_FORM_SMS.ERROR]: payload => payload,

  [QuoteTypes.CONTINUE_WITH_CAPTURE_SHORT_LINK.REQUEST]: payload => payload,
  [QuoteTypes.CONTINUE_WITH_CAPTURE_SHORT_LINK.SUCCESS]: payload => payload,
  [QuoteTypes.CONTINUE_WITH_CAPTURE_SHORT_LINK.ERROR]: payload => payload,

  [QuoteTypes.FETCH_CAPTURE_FORM_CONDITIONS.REQUEST]: payload => payload,
  [QuoteTypes.FETCH_CAPTURE_FORM_CONDITIONS.SUCCESS]: payload => payload,
  [QuoteTypes.FETCH_CAPTURE_FORM_CONDITIONS.ERROR]: payload => payload,

  [QuoteTypes.SET_SEND_SMS_BUTTON_STATUS.REQUEST]: payload => payload,

  [QuoteTypes.UPLOAD_CAPTURE_FORM_MEDIA.REQUEST]: payload => payload,
  [QuoteTypes.UPLOAD_CAPTURE_FORM_MEDIA.SUCCESS]: payload => payload,
  [QuoteTypes.UPLOAD_CAPTURE_FORM_MEDIA.ERROR]: payload => payload,

  [QuoteTypes.UPLOAD_CAPTURE_FORM_VIDEO.REQUEST]: payload => payload,
  [QuoteTypes.UPLOAD_CAPTURE_FORM_VIDEO.SUCCESS]: payload => payload,
  [QuoteTypes.UPLOAD_CAPTURE_FORM_VIDEO.ERROR]: payload => payload,

  [QuoteTypes.GET_SALVAGE_OFFER_PRICE.REQUEST]: payload => payload,
  [QuoteTypes.GET_SALVAGE_OFFER_PRICE.SUCCESS]: payload => payload,
  [QuoteTypes.GET_SALVAGE_OFFER_PRICE.ERROR]: payload => payload,

  [QuoteTypes.GET_SALVAGE_OFFER_FORM_CONFIG.REQUEST]: payload => payload,
  [QuoteTypes.GET_SALVAGE_OFFER_FORM_CONFIG.SUCCESS]: payload => payload,
  [QuoteTypes.GET_SALVAGE_OFFER_FORM_CONFIG.ERROR]: payload => payload,

  [QuoteTypes.SET_SALVAGE_OFFER_START_SEGMENT.REQUEST]: payload => payload,

  [QuoteTypes.UPLOAD_SALVAGE_OFFER_MEDIA.REQUEST]: payload => payload,
  [QuoteTypes.UPLOAD_SALVAGE_OFFER_MEDIA.SUCCESS]: payload => payload,
  [QuoteTypes.UPLOAD_SALVAGE_OFFER_MEDIA.ERROR]: payload => payload,

  [QuoteTypes.REDIRECT_TO_STEP_BY_QUOTE_STATUS]: payload => payload,

  [QuoteTypes.SET_BETTER_THAN_SCRAP_SEGMENT.REQUEST]: payload => payload,
});
